import { useFormAction, useNavigation } from '@remix-run/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'

/**
 * @deprecated use version from @conkoa/core/utils/get-error-message
 */
export function getErrorMessage(
  error: unknown,
  defaultMessage = 'Unknown Error',
) {
  if (typeof error === 'string') return error
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message
  }
  // eslint-disable-next-line no-console
  console.error('Unable to get error message for error', error)
  return defaultMessage
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get('X-Forwarded-Host') ??
    request.headers.get('host') ??
    new URL(request.url).host
  const protocol = host.includes('localhost') ? 'http' : 'https'
  return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
  // spelling errors and whatever makes this annoyingly inconsistent
  // in my own testing, `referer` returned the right value, but 🤷‍♂️
  const referrer =
    request.headers.get('referer') ??
    request.headers.get('referrer') ??
    request.referrer
  const domain = getDomainUrl(request)
  if (referrer?.startsWith(domain)) {
    return referrer.slice(domain.length)
  }
  return '/'
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const merged = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      merged.set(key, value)
    }
  }
  return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {}
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    }
  }
  return combined
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
  // biome-ignore lint/suspicious/noExplicitAny:
  condition: any,
  message: string | (() => string),
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === 'function' ? message() : message)
  }
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
  // biome-ignore lint/suspicious/noExplicitAny:
  condition: any,
  message: string | (() => string),
  responseInit?: ResponseInit,
): asserts condition {
  if (!condition) {
    throw new Response(typeof message === 'function' ? message() : message, {
      status: 400,
      ...responseInit,
    })
  }
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
  formAction,
  formMethod,
  delay = 400,
  minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
  Parameters<typeof useSpinDelay>[1] = {}) {
  const isPending = useIsPending({ formAction, formMethod })
  const delayedIsPending = useSpinDelay(isPending, {
    delay,
    minDuration,
  })
  return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  // biome-ignore lint/complexity/noForEach:
  return (...args: Args) => fns.forEach((fn) => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false)

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
      () => setDoubleCheck(false)

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
      doubleCheck
        ? undefined
        : (e) => {
            e.preventDefault()
            setDoubleCheck(true)
          }

    const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] = (
      e,
    ) => {
      if (e.key === 'Escape') {
        setDoubleCheck(false)
      }
    }

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
      onKeyUp: callAll(onKeyUp, props?.onKeyUp),
    }
  }

  return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
  fn: Callback,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout> | null = null
  return (...args: Parameters<Callback>) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

/**
 * Debounce a callback function
 */
export function useDebounce<
  Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  })
  return useMemo(
    () =>
      debounce(
        (...args: Parameters<Callback>) => callbackRef.current(...args),
        delay,
      ),
    [delay],
  )
}

/**
 * Custom hook for debouncing a value.
 * @template T - The type of the value to be debounced.
 * @param {T} value - The value to be debounced.
 * @param {number} [delay] - The delay in milliseconds for debouncing. Defaults to 500 milliseconds.
 * @returns {T} The debounced value.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-debounce)
 * @example
 * const debouncedSearchTerm = useDebounce(searchTerm, 300);
 */
export function useDebounceValue<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay ?? 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

// export async function downloadFile(url: string, retries: number = 0) {
//   const MAX_RETRIES = 3
//   try {
//     const response = await fetch(url)
//     if (!response.ok) {
//       throw new Error(`Failed to fetch image with status ${response.status}`)
//     }
//     const contentType = response.headers.get('content-type') ?? 'image/jpg'
//     const blob = Buffer.from(await response.arrayBuffer())
//     return { contentType, blob }
//   } catch (e) {
//     if (retries > MAX_RETRIES) throw e
//     return downloadFile(url, retries + 1)
//   }
// }

/**
 * Replaces special characters in a filename with hyphens and removes leading/trailing hyphens and whitespace.
 * @param filename - The filename to format.
 * @returns The formatted filename.
 */
export function formatFilename(filename: string) {
  return filename
    .replace(/[^a-zA-Z0-9.\-_]+/g, '-')
    .replace(/(\s*(\.|-)\s*)+/g, '$2')
    .replace(/-+(?=\.)|-+$/g, '')
    .replace(/^-+|-+$/g, '')
    .trim()
}

/**
 * Appends a timestamp to the given filename.
 * @param filename - The name of the file to append the timestamp to.
 * @returns The filename with the timestamp appended to it.
 */
export function appendTimestampToFilename(filename: string) {
  const timestamp = Date.now()
  const filenameArray = filename.split('.')
  const extension = filenameArray.length > 1 ? filenameArray.pop() : null
  const name = filename.replace(/\.[^/.]+$/, '')

  if (!extension) {
    return `${name}-${timestamp}`
  }

  return `${name}-${timestamp}.${extension}`
}

export function prepareFilename(filename: string) {
  return appendTimestampToFilename(formatFilename(filename))
}

/**
 * Lodash startCase implementation
 *
 * @example
 * startCase('fooBar') // => 'Foo Bar'
 * startCase('--foo-bar') // => 'Foo Bar'
 * startCase('__FOO_BAR__') // => 'FOO BAR'
 * @param str
 */
export function startCase(str: string) {
  return str
    .replace(/[\W_]+/g, ' ')
    .replace(/^[a-z]/, (m) => m.toUpperCase())
    .replace(/(?<=\s)[a-z]/g, (m) => m.toUpperCase())
}

export function useEffectOnce(effect: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(effect, [])
}

export function useWindowSize(): { width: number; height: number } {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export function getPhotoContentType(fileKey: string) {
  const ext = fileKey.split('.').pop()
  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    default:
      return 'application/octet-stream'
  }
}

/**
 * Used by client uploads where progress is needed.
 * Uses XMLHttpRequest.
 *
 * Thanks to Uploadthing for this code:
 */
export async function uploadPartWithProgress(
  opts: {
    url: string
    chunk: Blob
    fileType: string
    fileName: string
    contentDisposition: 'inline' | 'attachment'
    maxRetries: number
    onProgress?: (progressDelta: number) => void
  },
  retryCount = 0,
) {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('PUT', opts.url, true)
    xhr.setRequestHeader('Content-Type', opts.fileType)
    xhr.setRequestHeader(
      'Content-Disposition',
      [
        opts.contentDisposition,
        `filename="${opts.fileName}"`,
        `filename*=UTF-8''${opts.fileName}`,
      ].join('; '),
    )

    xhr.onload = async () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const etag = xhr.getResponseHeader('Etag')
        etag ? resolve(etag) : reject('NO ETAG')
      } else if (retryCount < opts.maxRetries) {
        // Add a delay before retrying (exponential backoff can be used)
        const delay = 2 ** retryCount * 1000
        await new Promise((res) => setTimeout(res, delay))
        await uploadPartWithProgress(opts, retryCount + 1) // Retry the request
      } else {
        reject('Max retries exceeded')
      }
    }

    let lastProgress = 0

    xhr.onerror = async () => {
      lastProgress = 0
      if (retryCount < opts.maxRetries) {
        // Add a delay before retrying (exponential backoff can be used)
        const delay = 2 ** retryCount * 1000
        await new Promise((res) => setTimeout(res, delay))
        await uploadPartWithProgress(opts, retryCount + 1) // Retry the request
      } else {
        reject('Max retries exceeded')
      }
    }

    xhr.upload.onprogress = (e) => {
      const delta = e.loaded - lastProgress
      lastProgress += delta
      opts.onProgress?.(delta)
    }

    xhr.send(opts.chunk)
  })
}

export function useUploadWithProgress({
  url,
  file,
  onUploaded,
}: {
  url: string
  file: File
  onUploaded?: (url: string, etag: string) => Promise<void>
}) {
  const flag = useRef(false)
  const [uploaded, setUploaded] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (flag.current || uploaded) {
      return
    }
    flag.current = true

    uploadPartWithProgress({
      url,
      chunk: file,
      fileType: file.type,
      fileName: file.name,
      contentDisposition: 'inline',
      maxRetries: 3,
    })
      .then(async (etag) => {
        await onUploaded?.(url, etag)
        setUploaded(true)
      })
      .catch((error) => {
        console.error(error)
        throw new Error('Failed to upload file')
      })
  }, [flag, file, url, onUploaded, uploaded])

  return { uploaded }
}

export function extensionToFileType(extension: string) {
  switch (extension) {
    case 'pdf':
      return 'application/pdf'
    case 'csv':
      return 'text/csv'
    case 'txt':
      return 'text/plain'
    case 'json':
      return 'application/json'
    case 'png':
      return 'image/png'
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    default:
      return 'application/octet-stream'
  }
}

export function assertNever(value: never): never {
  throw new Error(`Unexpected value: ${value}`)
}

export function privateEmailDisplay(email: string) {
  return (
    email
      // replace all characters after the first two with asterisks up to the @ symbol. Then replace all characters expect for the first four after the @ symbol and the top level domain with asterisks.
      .replace(/(?<=.{2}).*(?=@)/, (match) => '*'.repeat(match.length))
      .replace(/(?<=@.{4}).*(?=\..{2})/, (match) => '*'.repeat(match.length))
  )
}

export function leftPad(value: number, length: number, pad = '0') {
  return value.toString().padStart(length, pad)
}
